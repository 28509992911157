import React from 'react';
import Subscribe from './Subscribe';
interface ContactProps {
  type?: string;
  data?: any;
}
function Contact({ type, data }: ContactProps) {
  const { addressKor, addressEng, tel, email } = data;
  const iframe = data.mapUrl;
  const aboutType = type === 'about';

  return (
    <div className="contact-container">
      {/* {aboutType && <Subscribe />} */}
      <div className="dic">
        <h4>주소</h4>
        <p>{addressKor}</p>
      </div>
      <div className="dic">
        <h4>Address</h4>
        <p>{addressEng}</p>
      </div>
      <div className="dic">
        <h4>Contact</h4>
        <p>{tel}</p>
        <p>{email}</p>
      </div>
      <div className="map-container">
        <div dangerouslySetInnerHTML={{ __html: iframe }} />
      </div>
    </div>
  );
}

export default Contact;
