/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef, useState } from 'react';
import classnames from 'classnames';
import { Switch, Route } from 'react-router-dom';
import { TransitionGroup, CSSTransition } from 'react-transition-group';
import { CURSOR_DEFAULT } from 'type';
import * as commonAction from 'store/modules/common';
import Home from 'views/Home';
import Men from 'views/Men';
import Women from 'views/Women';
import News from 'views/News';
import About from 'views/About';
import Contact from 'views/Contact';
import Detail from 'views/Detail';
import ErrorPage from 'components/Error/ErrorPage';
import { useSelector } from 'react-redux';
import { useActions, usePrevious, useWindowSize } from 'lib/hooks';
import Header from 'components/Header';
import MainLoading from 'components/Loading/MainLoading';
import { isMobile } from 'react-device-detect';
import ImageViewer from 'components/ImageViewer';
function Navigation({ location }) {
  //router
  const timer = useRef(null);
  const scrollRef = useRef(null);
  const { pageLoad, imageViewer } = useSelector(state => ({
    scrollY: state.common.scrollY,
    pageLoad: state.common.pageLoad,
    imageViewer: state.common.imageViewer,
  }));
  const [windowHeight] = useWindowSize();
  const [transition, setTransition] = useState(false);
  const [init, setInit] = useState(true);
  const [progress, setProgress] = useState(0);
  const { setPageLoaded, setLoaded, resetLoaded, setPageType, setCursorType, setScrollY } = useActions(commonAction);
  const pageTimer = useRef(null);
  const [isLoading, setIsLoading] = useState(false);

  const prevLocation = usePrevious(location.pathname);
  useEffect(() => {
    const initLoad = async () => {
      setTransition(false);
      await setPageLoaded(false);
      if (typeof prevLocation !== 'undefined') {
        //move location

        setInit(false);
        setIsLoading(false);
      } else {
        //init refresh
        setIsLoading(true);
      }
    };
    const handleScroll = e => {
      const doc = document.documentElement;
      const scrollTop = (window.pageYOffset || doc.scrollTop) - (doc.clientTop || 0);

      setScrollY(scrollTop);
    };
    initLoad();
    window.addEventListener('scroll', handleScroll);
  }, [location.pathname]);

  useEffect(() => {
    if (!init) {
      if (!pageLoad) {
        timer.current = setTimeout(() => {
          setProgress(progress + 1);
        }, 500);
      } else {
        clearTimeout(timer.current);
        setProgress(0);
      }
      if (progress > 2) {
        clearTimeout(timer.current);
        setIsLoading(true);
        resetLoaded();
      }
    }
  }, [pageLoad, progress, init, resetLoaded]);

  const onLoadingEnd = async () => {
    await setIsLoading(false);
    setLoaded();
  };
  const handleEnter = () => {
    clearTimeout(pageTimer.current);
    pageTimer.current = setTimeout(() => {
      window.scrollTo(0, 0);
    }, 300);
  };
  const handleEntering = () => {};
  const handleEntered = () => {
    setCursorType(CURSOR_DEFAULT);
    if (location.pathname.indexOf('models') > 0) {
      setPageType('detail');
    } else {
      setPageType('default');
    }
  };

  return (
    <>
      <Header className="fixed" transition={transition} />
      <TransitionGroup className="frame">
        <CSSTransition
          key={location.pathname}
          timeout={1000}
          classNames="frame"
          onEnter={handleEnter}
          onEntering={handleEntering}
          onEntered={handleEntered}>
          <div
            ref={scrollRef}
            className={classnames('wrap', { mobile: isMobile })}
            // onScroll={handleDomScroll}
            style={{ height: windowHeight, minHeight: windowHeight }}>
            <Switch location={location}>
              <Route exact path="/" component={Home} />
              <Route path="/men" component={Men} />
              <Route path="/women" component={Women} />
              <Route path="/news" component={News} />
              <Route path="/about" component={About} />
              <Route path="/contact" component={Contact} />
              <Route path="/models/:id" component={Detail} />
              <Route path="*" component={ErrorPage} />
            </Switch>
          </div>
        </CSSTransition>
      </TransitionGroup>
      {/* <FloatingBtn /> */}
      <TransitionGroup component={React.Fragment}>
        {isLoading && (
          <CSSTransition key={isLoading} timeout={1000} classNames="loading-box">
            <MainLoading load={pageLoad} onEnd={onLoadingEnd} />
          </CSSTransition>
        )}
      </TransitionGroup>
      <TransitionGroup>
        {imageViewer.open && (
          <CSSTransition key={'news-image-viewer'} timeout={500} classNames="image-viewer">
            <ImageViewer data={imageViewer.data} index={imageViewer.index} name={imageViewer.name} />
          </CSSTransition>
        )}
      </TransitionGroup>
    </>
  );
}

export default Navigation;
