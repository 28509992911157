import React, { useRef } from 'react';
import env from 'env';
import BackImage from 'components/Image/BackImage';

function ExpandImage({ src }) {
  const imageRef = useRef(null);

  // const transformHeight = () => {
  //   const height = -(0 + scrollY * 0.1);
  //   const setHeightPosition = height > 0 ? 0 : height;
  //   return `translate3d(0,${setHeightPosition}%,0)`;
  // };
  return (
    <div className="image-wrap" style={{ position: 'relative' }}>
      <div
        className="expand-image"
        style={{
          position: 'absolute',
          top: 0,
          right: 0,
          bottom: 0,
          left: 0,
          overflow: 'hidden',
          perspective: '100px',
        }}>
        <div ref={imageRef} style={{ height: '100%' }}>
          <BackImage src={`${env.ENDPOINT_URL}/${src || ''}`} />
        </div>
      </div>
    </div>
  );
}

export default ExpandImage;
