import { compose, createStore, applyMiddleware } from 'redux';
import thunk from 'redux-thunk';
import rootReducers from './modules';

const composeEnhancers = (() => {
  if (process.env.NODE_ENV === 'development' && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) {
    return window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__;
  } else {
    return compose;
  }
})();

const store = createStore(rootReducers, composeEnhancers(applyMiddleware(thunk)));

export default store;
