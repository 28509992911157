import React from 'react';
import { PAGE } from 'type';
import ProfileCard from 'components/ProfileCard';

function OtherModels({ data }) {
  return (
    <div className="other-models-container detail">
      <div className="other-models-wrap">
        <h2 className="title">Meet other models</h2>
        <div className="profile-wrap">
          {data.map((item, i) => {
            return <ProfileCard key={i} id={item.id} type={PAGE} data={item} />;
          })}
        </div>
      </div>
    </div>
  );
}

export default OtherModels;
