import ContactItems from 'components/Contact';
import Footer from 'components/Footer';
import BackImage from 'components/Image/BackImage';
import env from 'env';
import { useActions } from 'lib/hooks';
import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import * as commonAction from 'store/modules/common';
import Layout from 'views/Layout';

function Contact(props) {
  const { pageLoad, contact } = useSelector(state => ({
    pageLoad: state.common.pageLoad,
    contact: state.common.contact,
  }));
  const { setPageLoaded } = useActions(commonAction);
  useEffect(() => {
    if (!pageLoad) {
      setPageLoaded(true);
    }
  }, [pageLoad, setPageLoaded]);

  return (
    <Layout className="about">
      {/* <Helmet title="Contact" /> */}
      <div className="contact-section">
        <h2 className="title">Contact</h2>
        <div className="image-wrap">
          <BackImage src={`${env.ENDPOINT_URL}/${contact.contactImg || ''}`} />
        </div>
        <ContactItems type={'about'} data={contact} />
      </div>
      <Footer data={contact} />
    </Layout>
  );
}

export default Contact;
