import produce from 'immer';
import axios from 'axios';
const NEWS_ITEMS_START = 'NEWS_ITEMS_START';
const NEWS_ITEMS_SUCCESS = 'NEWS_ITEMS_SUCCESS';
const NEWS_ITEMS_FAILURE = 'NEWS_ITEMS_FAILURE';

export const setNewsItems = () => dispatch => {
  dispatch({ type: NEWS_ITEMS_START });

  return axios.get('/api/news').then(res => {
    return dispatch({ type: NEWS_ITEMS_SUCCESS, payload: res.data });
  });
};

const initialState = {
  data: [],
  pending: false,
};

// reducer
export default function men(state = initialState, action) {
  switch (action.type) {
    case NEWS_ITEMS_START: {
      const nextState = produce(state, draftState => {
        draftState.pending = true;
      });

      return nextState;
    }
    case NEWS_ITEMS_SUCCESS: {
      const nextState = produce(state, draftState => {
        draftState.pending = false;
        draftState.data = action.payload;
      });

      return nextState;
    }
    case NEWS_ITEMS_FAILURE: {
      const nextState = produce(state, draftState => {
        draftState.pending = false;
        draftState.data = action.payload;
      });

      return nextState;
    }

    default:
      return state;
  }
}
