import classnames from 'classnames';
import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getRecentNotice } from '../../store/modules/common';
import env from 'env';

function NoticePopup() {
  const [show, setShow] = useState(false);
  const [width, setWidth] = useState(0);
  const [height, setHeight] = useState(0);
  const notice = useSelector(state => state.common.notice);
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getRecentNotice());
  }, [dispatch]);

  useEffect(() => {
    if (notice?.img && typeof localStorage !== 'undefined') {
      const value = localStorage.getItem(`popup-${notice.id}`);
      if (value) {
        console.log(new Date(), Number(value));
        if (new Date() < Number(value)) {
          return;
        }
      }
      const newImg = document.createElement('img');
      newImg.addEventListener('load', function () {
        console.log(this.width, this.height);
        const widthLonger = this.width > this.height;
        if (widthLonger) {
          const ratio = this.width / 500;
          setHeight(this.height / ratio);
          setWidth(500);
        } else {
          const ratio = this.height / 500;
          setHeight(500);
          setWidth(this.width / ratio);
        }
        setShow(true);
      });
      newImg.src = `${env.ENDPOINT_URL}/${notice.img}`;
    }
  }, [notice]);

  const dismiss = useCallback(() => {
    setShow(false);
  }, []);

  const dismissForOneDay = useCallback(() => {
    if (typeof localStorage !== 'undefined') {
      const tomorrow = new Date();
      tomorrow.setDate(tomorrow.getDate() + 1);
      localStorage.setItem(`popup-${notice.id}`, tomorrow.getTime());
    }
    setShow(false);
  }, [notice]);

  if (!show) {
    return null;
  }

  if (!notice || !notice.img) {
    return null;
  }

  return (
    <div className={classnames('popup-dim')}>
      <div className={classnames('popup-container')}>
        <div className={classnames('popup-desktop')}>
          <div className={classnames('popup-close')} onClick={dismiss}>
            &times;
          </div>
          <div className={classnames('popup-body')}>
            <img src={`${env.ENDPOINT_URL}/${notice.img}`} style={{ width, height }} />
          </div>
          <div onClick={dismissForOneDay} className={classnames('popup-close-until')}>
            하루 동안 안 보기
          </div>
        </div>
        <div className={classnames('popup-mobile')}>
          <div onClick={dismissForOneDay} className={classnames('popup-close-until mobile')}>
            하루 동안 안 보기
          </div>
          <div className={classnames('popup-close')} onClick={dismiss}>
            &times;
          </div>
          <div className={classnames('popup-body')}>
            <img src={`${env.ENDPOINT_URL}/${notice.img}`} style={{ width, height }} />
          </div>
        </div>
      </div>
    </div>
  );
}
export default NoticePopup;
