import './styles/style.scss';

import React, { useEffect, useState } from 'react';
import { isMobile } from 'react-device-detect';
import { BrowserRouter as Router, Route } from 'react-router-dom';
import WebFont from 'webfontloader';

import NoticePopup from './components/NoticePopup';
import Cursor from './components/Cursor';
import TransitionLayer from './components/Overlay/TransitionLayer';
import Navigation from './views/Navigation';

function App() {
  const [fontLoad, setFontLoad] = useState(false);
  useEffect(() => {
    WebFont.load({
      google: {
        families: ['Montserrat:500,600,800'],
      },
      loading: function () {
        setFontLoad(false);
      },
      inactive: () => {
        console.log('not loaded');
        setFontLoad(true);
      },
      active: function () {
        setFontLoad(true);
      },
    });
  }, []);

  return (
    <Router>
      <Route
        render={({ location }) =>
          fontLoad &&
          (
            <div>
              <Navigation location={location} />
              <TransitionLayer location={location.pathname} />
              {!isMobile && <Cursor />}
              <NoticePopup />
            </div>
          ).props.children
        }
      />
    </Router>
  );
}

export default App;
