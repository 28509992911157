import React, { useState, useEffect, useCallback, useRef } from 'react';
import env from 'env';
import BackImage from 'components/Image/BackImage';
import classnames from 'classnames';
import { useActions, usePrevious } from 'lib/hooks';
import { useSelector } from 'react-redux';
import * as commonAction from 'store/modules/common';
import { CSSTransition } from 'react-transition-group';
import { NavLink, useHistory, useLocation } from 'react-router-dom';
import { CURSOR_MENU, CURSOR_DEFAULT } from 'type';
import Nav from './Nav';
import Logo from './Logo';
import NavbarArrow from './NavbarArrow';
import Hamberger from './Hamberger';
function Header({ className, transition }) {
  const prevY = useRef(0);
  const [menuOpen, setMenuOpen] = useState(false);
  const [move, setMove] = useState(false);
  const [hide, setHide] = useState(false);
  const [dir, setDir] = useState('');
  const history = useHistory();
  const location = useLocation();
  const { contact, scrollY, pageType, display, headerColor, detailItem, imageViewer } = useSelector(state => ({
    imageViewer: state.common.imageViewer,
    contact: state.common.contact,
    pageType: state.common.pageType,
    display: state.common.header.display,
    headerColor: state.common.header.color,
    scrollY: state.common.scrollY,
    detailItem: state.models.detailItem,
  }));
  const cursor = useRef('');
  const { setCursorType } = useActions(commonAction);
  const prevLocation = usePrevious(location.pathname);
  useEffect(() => {
    setMenuOpen(false);
    if (location.pathname !== prevLocation) {
      setMove(true);
    } else {
      setMove(false);
    }
  }, [location, prevLocation]);
  const handleScroll = useCallback(() => {
    if (prevY.current > scrollY) {
      //scroll up
      setHide(false);
      setDir('up');
      if (scrollY > 50) {
        setDir('up');
      } else {
        setDir('');
      }
    } else if (prevY.current < scrollY) {
      setDir('down');
      if (scrollY > 50) {
        setHide(true);
      } else {
        setHide(false);
      }
    }
    prevY.current = scrollY;
  }, [scrollY]);
  useEffect(() => {
    handleScroll();
  }, [handleScroll, scrollY]);

  const handleMenuOpen = () => {
    setMenuOpen(!menuOpen);
    setMove(false);
  };

  const handleChangeCursor = useCallback(
    payload => {
      if (cursor.current !== payload) {
        cursor.current = payload;
        setCursorType(payload);
      }
    },
    [setCursorType]
  );
  const handleMouseOver = useCallback(
    e => {
      handleChangeCursor(CURSOR_MENU);
    },
    [handleChangeCursor]
  );
  const handleMouseLeave = useCallback(
    e => {
      handleChangeCursor(CURSOR_DEFAULT);
    },
    [handleChangeCursor]
  );
  const handleMoveModel = () => {
    if (detailItem.gender === 'f') {
      history.push('/women');
    } else {
      history.push('/men');
    }
  };

  return (
    <>
      <div
        className={classnames(
          'header',
          headerColor,
          pageType,
          className,
          dir,
          { hide: transition ? true : hide },
          { menu: menuOpen }
        )}
        style={{ display: display }}>
        <h1 className="logo">
          <NavLink exact activeClassName="active" to="/">
            <Logo />
          </NavLink>
          <div className="text" />
        </h1>
        {pageType === 'detail' && (
          <div className="nav-text-wrap">
            <span className="step step_1" onClick={handleMoveModel}>
              {detailItem.gender === 'f' ? 'Women' : 'Men'}
            </span>
            <NavbarArrow />
            <span className="step step_2">{detailItem.name}</span>
          </div>
        )}
      </div>
      {!imageViewer.open && (
        <div className="btn-menu-wrap">
          <div
            className="btn-menu"
            onClick={handleMenuOpen}
            onMouseOver={handleMouseOver}
            onMouseOut={handleMouseLeave}>
            <Hamberger open={menuOpen} />
          </div>
        </div>
      )}

      <CSSTransition in={menuOpen} timeout={1000} classNames="menu">
        <div className="header-back" />
      </CSSTransition>
      <CSSTransition in={menuOpen} timeout={1000} classNames="menu">
        <Nav active={menuOpen} move={move} />
      </CSSTransition>
      <CSSTransition in={menuOpen} timeout={1000} classNames="menu">
        <div className={classnames('header-image-wrap', { move: move })}>
          <BackImage className="menu" src={`${env.ENDPOINT_URL}/${contact.menuImg || ''}`} />
        </div>
      </CSSTransition>
    </>
  );
}

export default Header;
