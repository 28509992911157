import React, { useEffect, useState, useCallback, ReactNode } from 'react';
import { useActions, useError } from 'lib/hooks';
import * as commonAction from 'store/modules/common';
import classnames from 'classnames';
import ErrorPage from 'components/Error/ErrorPage';
interface LayoutProps {
  children?: ReactNode;
  onLoad?: (value: boolean) => void;
  className?: string;
}
function Layout({ children, onLoad, className }: LayoutProps) {
  const { setContact } = useActions(commonAction);
  const [loaded, setLoaded] = useState(false);
  const [error, setError] = useError();
  const initLoad = useCallback(async () => {
    try {
      await setContact();
      if (onLoad) await onLoad(true);
    } catch (err) {
      setError(err);
    }
    setLoaded(true);
  }, [onLoad, setContact, setError]);
  useEffect(() => {
    initLoad();
  }, [initLoad]);

  return error ? (
    <ErrorPage type="500" />
  ) : (
    <div className={classnames('layout-page', className)}>{loaded && <>{children}</>}</div>
  );
}
Layout.defaultProps = {
  onLoad: () => null,
};

export default Layout;
