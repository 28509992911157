export const MAIN = 'MAIN';
export const PAGE = 'PAGE';
export const DETAIL = 'DETAIL';
export const CURSOR_DETAIL = 'CURSOR_DETAIL';
export const CURSOR_MOVE = 'CURSOR_MOVE';
export const CURSOR_ARROW_LEFT = 'CURSOR_ARROW_LEFT';
export const CURSOR_ARROW_RIGHT = 'CURSOR_ARROW_RIGHT';
export const CURSOR_VIEWER_ARROW = 'CURSOR_VIEWER_ARROW';
export const CURSOR_VIEWER_CLOSE = 'CURSOR_VIEWER_CLOSE';
export const CURSOR_DEFAULT = 'CURSOR_DEFAULT';
export const CURSOR_MENU = 'CURSOR_MENU';
