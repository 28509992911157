import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import { TransitionGroup, CSSTransition } from 'react-transition-group';
BannerBox.propTypes = {
  width: PropTypes.number,
  textHeight: PropTypes.number,
  profileSize: PropTypes.number,
  rightGap: PropTypes.number,
  isLoading: PropTypes.bool,
  index: PropTypes.number,
  data: PropTypes.any,
};
function BannerBox({ width, textHeight, profileSize, rightGap, index = 0, data }) {
  const messageRef = useRef(null);

  const currentIndex = index || 0;
  const bottom = Math.round((profileSize - rightGap) * 0.945);
  const padding = Math.round(width * 0.0636);
  const numberSize = Math.round(width * 0.022);
  const titleSize = Math.round(width * 0.085);
  const titleGap = Math.round(width * 0.033);

  return (
    <TransitionGroup className="banner-box-container" style={{ height: textHeight, right: -rightGap, bottom: bottom }}>
      <CSSTransition key={index} timeout={1200} classNames="banner-box">
        <div className="banner-box-wrap">
          <div className="static" style={{ height: textHeight }}>
            <div
              className="banner-box"
              style={{
                height: textHeight,
                padding: `0 ${padding}px 0 ${padding}px`,
              }}>
              <div className="title" style={{ height: textHeight, fontSize: numberSize }}>
                {currentIndex + 1} / {data.length}
              </div>
              <h2 ref={messageRef} style={{ fontSize: titleSize, marginLeft: titleGap }}>
                {data[currentIndex].name}
              </h2>
            </div>
          </div>
        </div>
      </CSSTransition>
    </TransitionGroup>
  );
}

export default BannerBox;
