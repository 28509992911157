import React from 'react';
import env from 'env';
import Badge from 'components/Status/Badge';
import classnames from 'classnames';
function Profile({ data, fixed, position }) {
  return (
    <div className={classnames('detail-profile-wrap', { fixed: fixed }, position)}>
      <div className="sticky-content">
        <h2 className="title">{data.name}</h2>
        <div className="status">
          {data.nationality && (
            <div
              className="national"
              style={{ backgroundImage: `url(${env.NATIONAL_IMG(data.nationality.toLowerCase())})` }}
            />
          )}

          <Badge status={data.status} />
        </div>
        <div className="detail-info-box">
          <div className="info-row">
            <div className="info">
              <span className="tit">Height</span>
              <span className="tit">Bust</span>
              <span className="tit">Waist</span>
              <span className="tit">Hips</span>
            </div>
            <div className="info">
              <span className="content">{data.height}</span>
              <span className="content">{data.bust}</span>
              <span className="content">{data.waist}</span>
              <span className="content">{data.hips}</span>
            </div>
          </div>
          <div className="info-row">
            <div className="info">
              <span className="tit">Shoes</span>
              <span className="tit">Hair color</span>
              <span className="tit">Eye color</span>
            </div>
            <div className="info">
              <span className="content">{data.shoes}</span>
              <span className="content">{data.hair}</span>
              <span className="content">{data.eye}</span>
            </div>
          </div>
        </div>
        <div className="social-link">
          <a className="instagram" href={data.instagram} alt={data.instagram} target="_blank">
            <span className="text">Instagram</span>
            <span className="icon"></span>
          </a>
        </div>
      </div>
    </div>
  );
}

export default Profile;
