import ExpandImage from 'components/EffectCard/ExpandImage';
import Footer from 'components/Footer';
import { useActions } from 'lib/hooks';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import * as commonAction from 'store/modules/common';
import Layout from 'views/Layout';
// import { Helmet } from 'react-helmet';

function About(props) {
  const { pageLoad, contact } = useSelector(state => ({
    pageLoad: state.common.pageLoad,
    contact: state.common.contact,
  }));
  const [loaded, setLoaded] = useState(false);
  const { setPageLoaded } = useActions(commonAction);
  useEffect(() => {
    if (!pageLoad) {
      setPageLoaded(true);
      setLoaded(true);
    }
  }, [pageLoad, setPageLoaded]);

  return (
    loaded && (
      <Layout location={props.location.pathname} className="about">
        {/* <Helmet title="About" /> */}
        <div className="contact-section">
          <h2 className="title">About</h2>
          <ExpandImage src={contact.aboutImg} />
          <div className="introduce" dangerouslySetInnerHTML={{ __html: contact.intro }} />
        </div>
        <Footer data={contact} />
      </Layout>
    )
  );
}

export default About;
