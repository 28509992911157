import produce from 'immer';
import axios from 'axios';
import { CURSOR_DEFAULT } from 'type';
const MAIN_LOAD_RESET = 'MAIN_LOAD_RESET';
const MAIN_LOAD = 'MAIN_LOAD';
const PAGE_LOAD = 'PAGE_LOAD';
const PAGE_TYPE = 'PAGE_TYPE';
const CURSOR_TYPE = 'CURSOR_TYPE';
const HEADER_DISPLAY = 'HEADER_DISPLAY';
const HEADER_COLOR = 'HEADER_COLOR';
const HEADER_INFO = 'HEADER_INFO';
const CONTACT_START = 'CONTACT_START';
const CONTACT_SUCCESS = 'CONTACT_SUCCESS';
const POST_SUBSCRIBE_START = 'POST_SUBSCRIBE_START';
const POST_SUBSCRIBE_SUCCESS = 'POST_SUBSCRIBE_SUCCESS';
const GET_RECENT_NOTICE_START = 'GET_RECENT_NOTICE_START';
const GET_RECENT_NOTICE_SUCCESS = 'GET_RECENT_NOTICE_SUCCESS';
const SET_IMAGE_VIEWER = 'SET_IMAGE_VIEWER';
const SCROLL_Y = 'SCROLL_Y';

export const setScrollY = payload => dispatch => {
  dispatch({ type: SCROLL_Y, payload });
};
export const setCursorType = payload => dispatch => {
  dispatch({ type: CURSOR_TYPE, payload });
};
export const setHeaderDisplay = payload => dispatch => {
  dispatch({ type: HEADER_DISPLAY, payload });
};
export const setHeaderColor = payload => dispatch => {
  dispatch({ type: HEADER_COLOR, payload });
};
export const setHeaderInfo = payload => dispatch => {
  dispatch({ type: HEADER_INFO, payload });
};
export const setLoaded = () => dispatch => {
  dispatch({ type: MAIN_LOAD });
};
export const resetLoaded = () => dispatch => {
  dispatch({ type: MAIN_LOAD_RESET });
};
export const setPageLoaded = payload => dispatch => {
  dispatch({ type: PAGE_LOAD, payload });
};
export const setPageType = payload => dispatch => {
  dispatch({ type: PAGE_TYPE, payload });
};
export const setImageViewer = payload => dispatch => {
  dispatch({ type: SET_IMAGE_VIEWER, payload });
};
export const setContact = () => dispatch => {
  dispatch({ type: CONTACT_START });

  return axios.get('/api/settings').then(res => {
    return dispatch({ type: CONTACT_SUCCESS, payload: res.data });
  });
};
export const getRecentNotice = payload => dispatch => {
  dispatch({ type: GET_RECENT_NOTICE_START });

  return axios.get('/api/notice').then(res => {
    return dispatch({ type: GET_RECENT_NOTICE_SUCCESS, payload: res.data });
  });
};
export const postSubscribe = payload => dispatch => {
  dispatch({ type: POST_SUBSCRIBE_START });

  return axios.post('/api/subscriber', { email: payload }).then(res => {
    return dispatch({ type: POST_SUBSCRIBE_SUCCESS, payload: res.data });
  });
};

const initialState = {
  notice: null,
  loaded: false,
  pageLoad: true,
  pageType: '',
  cursor: CURSOR_DEFAULT,
  contact: {},
  subscribe: {
    pending: false,
    data: '',
  },
  scrollY: 0,
  header: {
    display: 'flex',
    show: true,
    color: 'black',
    info: {
      gender: '',
      name: '',
    },
  },
  imageViewer: {
    open: false,
    data: [],
    index: 0,
    name: null,
  },
};

// reducer
export default function common(state = initialState, action) {
  switch (action.type) {
    case SCROLL_Y: {
      const nextState = produce(state, draftState => {
        draftState.scrollY = action.payload;
      });

      return nextState;
    }
    case HEADER_DISPLAY: {
      const nextState = produce(state, draftState => {
        draftState.header.display = action.payload;
      });

      return nextState;
    }
    case HEADER_INFO: {
      const nextState = produce(state, draftState => {
        draftState.header.info = action.payload;
      });

      return nextState;
    }
    case HEADER_COLOR: {
      const nextState = produce(state, draftState => {
        draftState.header.color = action.payload;
      });

      return nextState;
    }
    case CURSOR_TYPE: {
      const nextState = produce(state, draftState => {
        draftState.cursor = action.payload;
      });

      return nextState;
    }
    case MAIN_LOAD_RESET: {
      const nextState = produce(state, draftState => {
        draftState.loaded = false;
      });

      return nextState;
    }
    case MAIN_LOAD: {
      const nextState = produce(state, draftState => {
        draftState.loaded = true;
      });

      return nextState;
    }
    case PAGE_LOAD: {
      const nextState = produce(state, draftState => {
        draftState.pageLoad = action.payload;
      });

      return nextState;
    }
    case PAGE_TYPE: {
      const nextState = produce(state, draftState => {
        draftState.pageType = action.payload;
      });

      return nextState;
    }
    case SET_IMAGE_VIEWER: {
      const nextState = produce(state, draftState => {
        draftState.imageViewer.open = action.payload.open;
        draftState.imageViewer.data = action.payload.data;
        draftState.imageViewer.index = action.payload.index;
        draftState.imageViewer.name = action.payload.name;
      });

      return nextState;
    }
    case CONTACT_SUCCESS: {
      const nextState = produce(state, draftState => {
        draftState.contact = action.payload;
        draftState.header.display = 'flex';
      });

      return nextState;
    }
    case GET_RECENT_NOTICE_SUCCESS: {
      const nextState = produce(state, draftState => {
        draftState.notice = action.payload;
      });

      return nextState;
    }
    case POST_SUBSCRIBE_START: {
      const nextState = produce(state, draftState => {
        draftState.subscribe.pending = true;
      });

      return nextState;
    }
    case POST_SUBSCRIBE_SUCCESS: {
      const nextState = produce(state, draftState => {
        draftState.subscribe.pending = false;
        draftState.subscribe.data = action.payload;
      });

      return nextState;
    }
    default:
      return state;
  }
}
