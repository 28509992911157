import React, { useEffect, useState, useRef } from 'react';
import env from 'env';
import { CURSOR_DETAIL, CURSOR_DEFAULT } from 'type';
import { useSelector } from 'react-redux';
import { useActions, useError } from 'lib/hooks';
import * as modelsAction from 'store/modules/models';
import * as menAction from 'store/modules/men';
import * as womanAction from 'store/modules/women';
import * as commonAction from 'store/modules/common';
import BackImage from 'components/Image/BackImage';
import Image from 'components/Image/Image';
import Profile from './Profile';
import OtherModels from './OtherModels';
import Footer from 'components/Footer';
import Layout from 'views/Layout';
import Hover3d from 'components/EffectCard/Hover3d';
import ErrorPage from 'components/Error/ErrorPage';
// import { Helmet } from 'react-helmet';

function Detail({ match, location }) {
  const imagesRef = useRef(null);
  const otherRef = useRef(null);
  const [data, setData] = useState({});
  const [error, setError] = useError();
  const [loaded, setLoaded] = useState(false);
  const [viewerData, setViewerData] = useState([]);
  const [imageData, setImageData] = useState([]);
  const { setPageLoaded, setHeaderColor, setCursorType, setImageViewer } = useActions(commonAction);
  const { setDetailItem } = useActions(modelsAction);
  const { setMenItems } = useActions(menAction);
  const { setWomenItems } = useActions(womanAction);
  const { scrollY, men, women, contact } = useSelector(state => ({
    scrollY: state.common.scrollY,
    men: state.men,
    women: state.women,
    contact: state.common.contact,
  }));
  useEffect(() => {
    initLoad();

    return () => {
      setHeaderColor('dark');
    };
  }, [initLoad, setHeaderColor]);

  const initLoad = async () => {
    const modelId = match.params.id;
    try {
      const detail = await setDetailItem(modelId);
      if (detail.payload.gender === 'm') {
        await setMenItems();
      } else {
        await setWomenItems();
      }
      //mock
      // const Images = [
      //   {
      //     img: `${detail.payload.img}`,
      //   },
      //   {
      //     img: `${detail.payload.img}`,
      //   },
      //   {
      //     img: `${detail.payload.img}`,
      //   },
      // ];
      const Images = detail.payload.Images;

      const detailMain = [{ img: detail.payload.img }];
      const detailImages = detailMain.concat(Images);
      setData(detail.payload);
      setImageData(Images);
      setViewerData(detailImages);
      setHeaderColor('white');
    } catch (err) {
      setError(err);
    }
    setPageLoaded(true);
    setLoaded(true);
  };

  const handleOpenViwer = async payload => {
    setImageViewer({
      open: true,
      data: viewerData,
      index: payload,
      name: data.name,
    });
  };
  const handleFixed = () => {
    const element = imagesRef.current;
    if (element) {
      const offsetY = scrollY <= 0 ? 0 : scrollY;
      const elementTop = element.offsetTop - 110;
      const elementBottom = elementTop + element.clientHeight - element.clientWidth - 110;
      if (offsetY >= elementTop) {
        const offset = offsetY - elementTop;
        if (offset >= elementBottom) {
          return 'fixed bottom';
        } else {
          return 'fixed';
        }
      } else {
        return `default`;
      }
    }
  };

  return error ? (
    <ErrorPage type="500" />
  ) : (
    <>
      <Layout location={location.pathname}>
        {loaded && (
          <>
            {/* <Helmet
                title={data.name}
                meta={[
                  {
                    property: `og:title`,
                    content: data.name,
                  },
                  {
                    property: `og:image`,
                    content: `${env.ENDPOINT_URL}/${data.img}`,
                  },
                  {
                    property: `twitter:title`,
                    content: data.name,
                  },
                ]}
              /> */}
            <div className="detail-container">
              <div className="detail-wrap">
                <div
                  className="detail-main"
                  onClick={handleOpenViwer.bind(this, 0)}
                  onMouseOver={() => setCursorType(CURSOR_DETAIL)}
                  onMouseOut={() => setCursorType(CURSOR_DEFAULT)}>
                  <Hover3d strength={5}>
                    <BackImage src={`${env.ENDPOINT_URL}/${data.img || ''}`} />
                  </Hover3d>
                </div>
                <Profile data={data} position={handleFixed()} />

                <div className="image-container" ref={imagesRef}>
                  <div className="image-wrap">
                    {imageData.map((item, i) => {
                      return (
                        <div
                          key={i}
                          onClick={handleOpenViwer.bind(this, i + 1)}
                          onMouseOver={() => setCursorType(CURSOR_DETAIL)}
                          onMouseOut={() => setCursorType(CURSOR_DEFAULT)}>
                          <Hover3d strength={5}>
                            <Image src={`${env.ENDPOINT_URL}/${item.img}`} />
                          </Hover3d>
                        </div>
                      );
                    })}
                  </div>
                </div>
              </div>
              <div ref={otherRef}>
                <OtherModels data={data.gender === 'm' ? men.data : women.data} />
              </div>
            </div>

            <Footer data={contact} />
          </>
        )}
      </Layout>
    </>
  );
}

export default Detail;
