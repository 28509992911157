import './cursor.scss';

import {
  CURSOR_ARROW_LEFT,
  CURSOR_ARROW_RIGHT,
  CURSOR_DEFAULT,
  CURSOR_DETAIL,
  CURSOR_MENU,
  CURSOR_MOVE,
  CURSOR_VIEWER_ARROW,
  CURSOR_VIEWER_CLOSE,
} from 'type';
import React, { useCallback, useEffect, useState } from 'react';
import { animated, useSpring } from 'react-spring';

import Arrow from 'components/Buttons/Arrow';
import Plus from 'components/Buttons/Plus';
import ZoomArrow from '../Buttons/ZoomArrow';
import classnamse from 'classnames';
import { useSelector } from 'react-redux';

function Cursor() {
  const { cursorType } = useSelector(state => ({
    cursorType: state.common.cursor,
  }));
  const [load, setLoad] = useState(false);
  const fast = { tension: 800, friction: 40 };
  const [position, setPosition] = useSpring(() => ({
    xy: [0, 0],
    size: 20,
    border: 0,
    back: 1,
    blur: 0,
  }));

  useEffect(() => {
    window.addEventListener('mousemove', handleMoveCursor);
    window.addEventListener('mouseleave', handleReset);
  }, [handleMoveCursor]);
  useEffect(() => {
    switch (cursorType) {
      case CURSOR_ARROW_LEFT: {
        setPosition({ size: 70, immediate: false, border: 1, back: 0, blur: 0, type: 'arrow' });
        break;
      }
      case CURSOR_ARROW_RIGHT: {
        setPosition({ size: 70, immediate: false, border: 1, back: 0, blur: 0, type: 'arrow' });
        break;
      }
      case CURSOR_VIEWER_ARROW: {
        setPosition({ size: 70, immediate: false, border: 1, back: 0, blur: 0, type: 'arrow' });
        break;
      }
      case CURSOR_VIEWER_CLOSE: {
        setPosition({ size: 70, immediate: false, border: 0, back: 1, blur: 0, type: 'arrow' });
        break;
      }
      case CURSOR_DETAIL: {
        setPosition({ size: 70, immediate: false, border: 0, back: 0.5, blur: 6, type: 'detail' });
        break;
      }
      case CURSOR_MOVE: {
        setPosition({ size: 70, immediate: false, border: 0, back: 0.5, blur: 6 });
        break;
      }
      case CURSOR_MENU: {
        setPosition({ size: 70, immediate: false, border: 0, back: 1, blur: 0, type: 'menu' });
        break;
      }
      default: {
        setPosition({ size: 20, immediate: false, border: 0.7, back: 0.5, blur: 0, type: 'default' });

        return;
      }
    }
  }, [cursorType, setPosition]);
  const handleReset = () => {
    setLoad(false);
  };
  const handleMoveCursor = useCallback(
    e => {
      setLoad(true);
      setPosition({ xy: [e.clientX, e.clientY], immediate: true, config: fast });
    },
    [fast, setPosition]
  );
  const handleColor = ({ type, payload }) => {
    switch (payload) {
      case CURSOR_DEFAULT: {
        if (type === 'border') {
          return 255;
        } else {
          return 0;
        }
      }
      case CURSOR_VIEWER_ARROW: {
        return 255;
      }
      case CURSOR_VIEWER_CLOSE: {
        return 255;
      }
      case CURSOR_MOVE: {
        return 0;
      }
      default: {
        return 0;
      }
    }
  };

  const backColor = handleColor({ type: 'back', payload: cursorType });
  const borderColor = handleColor({ type: 'border', payload: cursorType });

  const trans = (x, y) => `translate3d(${x}px,${y}px,0) translate3d(-50%,-50%,0)`;
  const background = back => `rgba(${backColor},${backColor},${backColor},${back})`;
  const border = border => `rgba(${borderColor},${borderColor},${borderColor},${border})`;
  const blur = blur => `blur(${blur}px)`;

  return (
    <animated.div
      className={classnamse('cursor-wrapper', cursorType, { load: load })}
      style={{
        transform: position.xy.interpolate(trans),
        width: position.size,
        height: position.size,
        backgroundColor: position.back.interpolate(background),
        borderColor: position.border.interpolate(border),
        backdropFilter: position.blur.interpolate(blur),
        WebkitBackdropFilter: position.blur.interpolate(blur),
      }}>
      <div className="cursor" style={{ opacity: cursorType === CURSOR_DETAIL ? 1 : 0 }}>
        <Plus type="white" />
      </div>
      <div className="cursor" style={{ opacity: cursorType === CURSOR_MOVE ? 1 : 0 }}>
        <span>View</span>
      </div>
      <div className="cursor" style={{ opacity: cursorType === CURSOR_VIEWER_CLOSE ? 1 : 0 }}>
        <ZoomArrow />
      </div>
      <div
        className="cursor"
        style={{ opacity: cursorType === CURSOR_ARROW_LEFT || cursorType === CURSOR_ARROW_RIGHT ? 1 : 0 }}>
        <Arrow type={cursorType === CURSOR_ARROW_RIGHT ? 'next' : 'prev'}></Arrow>
      </div>
    </animated.div>
  );
}

export default Cursor;
