import React from 'react';
import env from 'env';
import { TransitionGroup, CSSTransition } from 'react-transition-group';
import MainImageItem from './MainImageItem';
function MainImageSlide({ src, width, pages, index, onLoad, openViewer, onMouseOver, onMouseOut }) {
  const currentIndex = index || 0;

  return (
    <TransitionGroup
      onMouseOver={onMouseOver}
      onMouseOut={onMouseOut}
      className="main-image-slide"
      style={{
        left: `calc(50% - ${width / 2}px)`,
        width: width,
        height: width,
      }}>
      <CSSTransition key={index} timeout={1200} classNames="main-image">
        <MainImageItem
          width={width}
          src={`${env.ENDPOINT_URL}/${pages[currentIndex].img}`}
          onClick={openViewer}
          onLoad={onLoad}
        />
      </CSSTransition>
    </TransitionGroup>
  );
}

export default MainImageSlide;
