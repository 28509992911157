import React, { useState, useEffect } from 'react';
import classnames from 'classnames';

function Hamberger({ open }) {
  const [end, SetEnd] = useState(false);
  useEffect(() => {
    if (!open) {
      SetEnd(false);
    }
  }, [open]);
  const handleTransitionEnd = () => {
    if (open) {
      SetEnd(true);
    }
  };

  return (
    <div className={classnames('hamberger', { active: open })}>
      <span className={classnames({ end: end })} />
      <span className={classnames({ end: end })} onTransitionEnd={handleTransitionEnd} />
      <span className={classnames({ end: end })} />
    </div>
  );
}

export default Hamberger;
