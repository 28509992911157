import './status.scss';
import React, { useEffect, useState } from 'react';
import classnames from 'classnames';
function Badge({ status, className }) {
  const [type, setType] = useState('');
  const [text, setText] = useState('');
  useEffect(() => {
    const initLoad = async () => {
      switch (status) {
        case '1': {
          await setType('inTown');
          await setText('In town');
          break;
        }
        case '2': {
          await setType('coming');
          await setText('Coming');
          break;
        }
        case '3': {
          await setType('outOfTown');
          await setText('Out of town');
          break;
        }
        default: {
          break;
        }
      }
    };
    initLoad();
  }, [status]);

  return (
    <div className={classnames('badge', type, className)}>
      <span className="ic-badge"></span>
      <span className="text">{text}</span>
    </div>
  );
}

export default Badge;
