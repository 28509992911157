import React from 'react';

function Logo() {
  return (
    <svg width="38" height="38" viewBox="0 0 38 38">
      <path
        fill="#060101"
        d="M10.584 19.91c4.211 0 7.624 3.379 7.624 7.545 0 4.167-3.413 7.545-7.624 7.545H3V19.91zm22.006 0V30.24H35V35H19.916V19.91H32.59zM24.071 3l3.387 3.146L30.844 3H35v15.088H19.916V3h4.155zM10.584 3c4.211 0 7.624 3.378 7.624 7.544 0 4.166-3.413 7.544-7.624 7.544H3V3z"
      />
    </svg>
  );
}

export default Logo;
