import React, { useState, useRef, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import { useActions } from 'lib/hooks';
import * as commonAction from 'store/modules/common';
import MainImageSlide from './MainImageSlide';
import BannerBox from './BannerBox';
import BannerProfile from './BannerProfile';
import classnames from 'classnames';
import { CURSOR_DETAIL, CURSOR_MOVE, CURSOR_ARROW_RIGHT, CURSOR_ARROW_LEFT, CURSOR_DEFAULT } from 'type';
import Hover3d from 'components/EffectCard/Hover3d';
MainImage.propTypes = {
  pages: PropTypes.array,
  width: PropTypes.number,
  isLoading: PropTypes.bool,
  openViewer: PropTypes.any,
  profileSize: PropTypes.number,
  rightGap: PropTypes.number,
  textHeight: PropTypes.number,
};
function MainImage({ pages, width, isLoading, openViewer, profileSize, rightGap, textHeight }) {
  const cursor = useRef('');
  const [imageLoad, setImageLoad] = useState(false);
  const mainImageRef = useRef(null);
  const { setCursorType } = useActions(commonAction);
  const [index, setIndex] = useState(0);
  const [intro, setIntro] = useState(false);
  const isClick = useRef(true);
  const interval = useRef(null);
  const moveDelay = useRef(null);
  const slideDelay = 4000;
  const autoPlayFunc = useCallback(() => {
    interval.current = setInterval(() => {
      setIndex(state => (state + 1) % pages.length);
    }, slideDelay);
  }, [pages.length]);
  const autoPlaySlider = useCallback(() => {
    clearInterval(interval.current);
    autoPlayFunc();
  }, [autoPlayFunc]);

  useEffect(() => {
    if (!isLoading) {
      setTimeout(() => {
        setIntro(true);
        if (!imageLoad) {
          clearInterval(interval.current);
        } else {
          autoPlaySlider();
        }
      }, 400);
    }
  }, [isLoading, imageLoad, autoPlaySlider]);

  const handleMove = e => {
    if (mainImageRef.current && !mainImageRef.current.contains(e.target)) {
      if (isClick.current) {
        isClick.current = false;
        clearInterval(interval.current);
        if (e.clientX > window.innerWidth / 2) {
          setIndex(state => (state + 1) % pages.length);
        } else {
          setIndex(state => (state - 1 < 0 ? pages.length - 1 : state - 1));
        }
        autoPlaySlider();
        moveDelay.current = setTimeout(() => {
          isClick.current = true;
        }, 1200);
      }
    }
  };
  const handleChangeCursor = useCallback(
    payload => {
      if (cursor.current !== payload) {
        cursor.current = payload;
        setCursorType(payload);
      }
    },
    [setCursorType]
  );
  const handleMouseMove = useCallback(
    e => {
      if (mainImageRef.current && !mainImageRef.current.contains(e.target)) {
        if (e.clientX > window.innerWidth / 2) {
          handleChangeCursor(CURSOR_ARROW_RIGHT);
        } else {
          handleChangeCursor(CURSOR_ARROW_LEFT);
        }
      }
    },
    [handleChangeCursor]
  );
  const handleImageLoad = payload => {
    setImageLoad(payload);
  };
  const handleNewsMove = useCallback(() => {
    clearInterval(interval.current);
    handleChangeCursor(CURSOR_DETAIL);
  }, [handleChangeCursor]);
  const handleProfileOver = useCallback(() => {
    clearInterval(interval.current);
    handleChangeCursor(CURSOR_MOVE);
  }, [handleChangeCursor]);
  const handleMouseLeave = useCallback(() => {
    if (cursor.current !== CURSOR_DEFAULT) {
      autoPlaySlider();
      handleChangeCursor(CURSOR_DEFAULT);
    }
  }, [autoPlaySlider, handleChangeCursor]);
  const handleViewerOpen = () => {
    openViewer(index);
  };

  return (
    <div className="main-section" onClick={handleMove} onMouseMove={handleMouseMove} onMouseOut={handleMouseLeave}>
      <div
        ref={mainImageRef}
        className={classnames('main-image-container', { intro: intro })}
        style={{ width: width, height: width, right: rightGap / 2 }}>
        <div style={{ position: 'relative', width: width, height: width }}>
          <Hover3d width={width} className="main-image-wrap">
            <MainImageSlide
              pages={pages}
              index={index}
              width={width}
              onMouseOver={handleNewsMove}
              onMouseOut={handleMouseLeave}
              onLoad={handleImageLoad}
              openViewer={handleViewerOpen}
            />
          </Hover3d>
          <BannerBox
            width={width}
            profileSize={profileSize}
            textHeight={textHeight}
            rightGap={rightGap}
            index={index}
            data={pages}
          />
        </div>
        <Hover3d
          className="banner-profile-container"
          width={profileSize}
          style={{
            top: width - profileSize,
            width: profileSize,
            height: profileSize,
            right: -rightGap,
            marginTop: rightGap,
          }}>
          <BannerProfile
            index={index}
            data={pages}
            width={width}
            profileSize={profileSize}
            rightGap={rightGap}
            onMouseOver={handleProfileOver}
            onMouseOut={handleMouseLeave}
          />
        </Hover3d>
      </div>
    </div>
  );
}

export default MainImage;
