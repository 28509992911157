/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import env from 'env';
import { useHistory } from 'react-router-dom';
import classnames from 'classnames';
import { TransitionGroup, CSSTransition } from 'react-transition-group';
import { isEmpty } from 'lodash-es';

function BannerProfile({ index, data, profileSize, onMouseOver, onMouseOut }) {
  const [imageLoad, setImageLoad] = useState(false);
  const history = useHistory();
  useEffect(() => {
    initLoad();
  }, []);
  const initLoad = async () => {
    await setImageLoad(false);
    const newImg = document.createElement('img');
    newImg.onload = () => {
      handleImageLoad();
    };
    newImg.onError = () => {
      handleImageError();
    };
    newImg.src = `${env.ENDPOINT_URL}/${data[index || 0].img}`;
  };
  const handleImageLoad = () => {
    setImageLoad(true);
  };
  const handleImageError = () => {
    setImageLoad(true);
  };
  const handleClick = () => {
    if (isEmpty(data[currentIndex].Model)) {
      return;
    } else {
      history.push(`/models/${data[currentIndex].Model.id}`);
    }
  };

  const currentIndex = index || 0;

  return (
    <TransitionGroup onMouseOver={onMouseOver} onMouseOut={onMouseOut} onClick={handleClick}>
      <CSSTransition key={index} timeout={1200} classNames="banner-profile">
        <div className="banner-profile-wrap">
          {!isEmpty(data[currentIndex].Model) && (
            <div
              className={classnames('background-loading', { load: imageLoad })}
              style={{ width: profileSize, height: profileSize }}>
              <div
                className="image"
                style={{
                  backgroundImage: `url("${env.ENDPOINT_URL}/${data[currentIndex].Model.img}")`,
                  width: profileSize,
                  height: profileSize,
                }}
              />
            </div>
          )}
        </div>
      </CSSTransition>
    </TransitionGroup>
  );
}

export default BannerProfile;
