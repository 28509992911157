import React, { useState, useEffect } from 'react';
import classnames from 'classnames';
function MainImageItem({ onClick, src, width, onLoad }) {
  const [imageLoad, setImageLoad] = useState(false);
  useEffect(() => {
    initLoad();
    onLoad(false);
  }, []);
  const initLoad = () => {
    const newImg = document.createElement('img');
    newImg.onload = () => {
      handleImageLoad();
    };
    newImg.onError = () => {
      handleImageError();
    };
    newImg.src = src;
  };
  const handleImageLoad = () => {
    setImageLoad(true);
    onLoad(true);
  };
  const handleImageError = () => {
    setImageLoad(true);
    onLoad(true);
  };

  return (
    <div className="main-image">
      <div
        className={classnames('background-loading main', { load: imageLoad })}
        style={{ width: width, height: width }}>
        <div
          className="item"
          style={{ backgroundImage: `url("${src}")`, width: width, height: width }}
          onClick={onClick}
        />
      </div>
      <div className="mask" />
    </div>
  );
}

export default MainImageItem;
