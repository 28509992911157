import React, { useEffect } from 'react';
import { useActions } from 'lib/hooks';
import { useSelector } from 'react-redux';

import * as commonAction from 'store/modules/common';
function ErrorPage({ type = '404' }) {
  const { pageLoad } = useSelector(state => ({
    pageLoad: state.common.pageLoad,
  }));
  const { setPageLoaded } = useActions(commonAction);
  useEffect(() => {
    if (!pageLoad) {
      setPageLoaded(true);
    }
  }, [pageLoad, setPageLoaded]);

  return (
    <div className="error-container">
      <div className="error-wrapper">
        {type === '404' ? (
          <div>
            <p>페이지를 찾을 수 없습니다.</p>
            <p>Page not found</p>
            <a href="/">Go home</a>
          </div>
        ) : (
          <div>
            <p>홈페이지를 연결할 수 없습니다.</p>
            <p>{`Can't connect to the homepage`}</p>
            <a href="/">Retry</a>
          </div>
        )}
      </div>
    </div>
  );
}

export default ErrorPage;
