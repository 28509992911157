import React from 'react';
import ProfileCard from '../../components/ProfileCard/index';
import { useSelector } from 'react-redux';
import { PAGE } from 'type';
import Footer from 'components/Footer';

function Model({ type, title, data }) {
  const { contact } = useSelector(state => ({
    contact: state.common.contact,
  }));

  const modelData = Array.isArray(data) ? data : [];

  return (
    <>
      <div className={`profile-section detail`}>
        <h2 className="title">{title}</h2>
        {modelData.length === 0 ? (
          <div className="empty-wrap">
            <p>Coming soon.</p>
            <p>We always welcome new models.</p>
            <p>
              Please <a href={`mailto: ${contact.email}`}>contact</a> us.
            </p>
          </div>
        ) : (
          <div className="profile-wrap">
            {modelData.map((item, i) => {
              return <ProfileCard key={i} id={item.id} type={PAGE} data={item} />;
            })}
          </div>
        )}
      </div>
      <Footer data={contact} />
    </>
  );
}

export default Model;
