import React, { useEffect, useState, useCallback } from 'react';
import env from 'env';
import { CURSOR_DEFAULT, CURSOR_DETAIL } from '../../type';
import { useSelector } from 'react-redux';
import { useActions, useError } from 'lib/hooks';
import * as newsAction from 'store/modules/news';
import * as commonAction from 'store/modules/common';
import StackGrid from 'react-stack-grid';
import sizeMe from 'react-sizeme';
import Card from './Card';
import './news.scss';
import Footer from 'components/Footer';
import Layout from 'views/Layout';
import ErrorPage from 'components/Error/ErrorPage';

function News(props) {
  const { setNewsItems } = useActions(newsAction);
  const { setPageLoaded, setCursorType, setImageViewer } = useActions(commonAction);
  const { news, contact } = useSelector(state => ({
    news: state.news,
    contact: state.common.contact,
  }));

  const [loaded, setLoaded] = useState(false);
  const [error, setError] = useError();
  const {
    size: { width },
  } = props;
  const initLoad = useCallback(async () => {
    try {
      await setNewsItems();
    } catch (err) {
      setError(err);
    }
    setLoaded(true);
    setPageLoaded(true);
  }, [setNewsItems, setError, setPageLoaded]);
  useEffect(() => {
    initLoad();
  }, [initLoad]);

  const sortFunc = (a, b) => {
    return b.order - a.order;
  };
  const handleClick = payload => {
    setImageViewer({
      open: true,
      data: news.data.slice().sort(sortFunc),
      index: payload,
    });
  };
  const newsData = Array.isArray(news.data) ? news.data : [];

  return error ? (
    <ErrorPage type="500" />
  ) : (
    loaded && (
      <Layout location={props.location.pathname}>
        <div className="news-container profile-section detail">
          <h2 className="title">News</h2>
          <div className="news-wrapper">
            <StackGrid
              monitorImagesLoaded={true}
              columnWidth={width <= 768 ? '50%' : '33.33%'}
              gutterWidth={width <= 768 ? 9 : 30}
              gutterHeight={width <= 768 ? 20 : 50}
              duration={0}>
              {newsData
                .slice()
                .sort(sortFunc)
                .map((item, i) => {
                  return (
                    <Card
                      key={i}
                      onClick={handleClick.bind(this, i)}
                      onMouseOver={() => setCursorType(CURSOR_DETAIL)}
                      onMouseOut={() => setCursorType(CURSOR_DEFAULT)}
                      img={`${env.ENDPOINT_URL}/${item.img}`}
                      name={item.name}
                      model={item.model}
                      date={item.date}
                    />
                  );
                })}
            </StackGrid>
          </div>
        </div>

        <Footer data={contact} />
      </Layout>
    )
  );
}

export default sizeMe()(News);
