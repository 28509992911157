import React from 'react';

function NavbarArrow({ type }) {
  const color = type === 'white' ? '#ffffff' : '#222222';

  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="6" height="8" viewBox="0 0 6 8">
      <path fill={color} d="M65 5L71 9 65 13z" transform="translate(-65 -5)" />
    </svg>
  );
}

export default NavbarArrow;
