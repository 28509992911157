import React, { useState, useEffect } from 'react';
import classnames from 'classnames';
function Image({ src }) {
  const [imageLoad, setImageLoad] = useState(false);
  useEffect(() => {
    initLoad();
  }, [initLoad]);
  const initLoad = () => {
    const newImg = document.createElement('img');
    newImg.onload = () => {
      handleImageLoad();
    };
    newImg.onError = () => {
      handleImageError();
    };
    newImg.src = src;
  };
  const handleImageLoad = () => {
    setImageLoad(true);
  };
  const handleImageError = () => {
    setImageLoad(true);
  };

  return (
    <div className={classnames('background-loading news', { load: imageLoad })}>
      <img src={src} alt={src} />
    </div>
  );
}

export default Image;
