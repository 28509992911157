import React, { useEffect, useState, useCallback } from 'react';
import env from 'env';
import { useSelector } from 'react-redux';
import { MAIN } from 'type';
import { useActions, useWindowSize, useError } from 'lib/hooks';
import * as commonAction from 'store/modules/common';
import * as menActions from 'store/modules/men';
import * as womenActions from 'store/modules/women';
import * as carouselActions from 'store/modules/carousel';
import Section from './Section';
import Contact from '../../components/Contact';
import Footer from 'components/Footer';
import MainImage from './MainImage';
import Layout from 'views/Layout';
import ErrorPage from 'components/Error/ErrorPage';
import { isEmpty } from 'lodash-es';
interface HomeProps {
  location: any;
}
function Home(props: HomeProps) {
  const { loaded, pageLoad, contact, carousel, men, women } = useSelector((state: any) => ({
    loaded: state.common.loaded,
    pageLoad: state.common.pageLoad,
    contact: state.common.contact,
    carousel: state.carousel,
    men: state.men,
    women: state.women,
  }));

  const [error, setError] = useError();
  const { setPageLoaded, setImageViewer } = useActions(commonAction);
  const { setMenItems } = useActions(menActions);
  const { setWomenItems } = useActions(womenActions);
  const { setCarouselItems } = useActions(carouselActions);
  const [windowWidth] = useWindowSize();
  const [width, setWidth] = useState(660);
  const [profileSize, setProfileSize] = useState(320);
  const [textHeight, setTextHeight] = useState(140);
  const [rightGap, setRightGap] = useState(96);
  const mainImageLoad = useCallback(async ({ data, count }) => {
    if (Array.isArray(data)) {
      const promises = await data.map(async (item, i) => {
        if (i < count) {
          await addImageProcess({
            src: env.ENDPOINT_URL + '/' + item.img,
          });
          await addImageProcess({
            src: env.ENDPOINT_URL + '/' + isEmpty(item.Model) ? '' : item.Model.img || '',
          });
        }
      });

      await Promise.all(promises);
    }
  }, []);
  const initLoad = useCallback(async () => {
    try {
      const data = await setCarouselItems();
      await mainImageLoad({ data: data.payload, count: 3 });
      await setWomenItems();
      await setMenItems();
      setPageLoaded(true);
      mainImageLoad({ data: data, count: data.payload.length });
    } catch (err) {
      setError(err);
      setPageLoaded(true);
    }
  }, [mainImageLoad, setCarouselItems, setError, setMenItems, setPageLoaded, setWomenItems]);
  const addImageProcess = ({ src }: { src: string }) => {
    return new Promise((resolve, reject) => {
      const img = document.createElement('img');
      img.onload = e => {
        resolve(true);
      };
      img.onerror = err => {
        resolve(true);
      };

      img.src = `${src}`;
    });
  };

  const handleViewerOpen = (payload: any) => {
    setImageViewer({
      open: true,
      data: carousel.data,
      index: payload,
    });
  };
  useEffect(() => {
    initLoad();
  }, [initLoad, pageLoad]);
  useEffect(() => {
    const maxWidth = 660;
    const maxRightProfile = 96;
    const maxMargin = 46;
    if (windowWidth < maxWidth + maxRightProfile + maxMargin * 2) {
      const resizeWidth = Math.round(windowWidth * 0.661);
      const profile = Math.round(resizeWidth * 0.485);
      const right = Math.round(resizeWidth * 0.14);
      const height = Math.round(resizeWidth * 0.218);
      setWidth(resizeWidth);
      setProfileSize(profile);
      setRightGap(right);
      setTextHeight(height);
    } else {
      setWidth(maxWidth);
      setProfileSize(320);
      setRightGap(96);
      setTextHeight(140);
    }
  }, [windowWidth]);

  return error ? (
    <ErrorPage type="500" />
  ) : (
    <>
      <Layout>
        {Array.isArray(carousel.data) && carousel.data.length !== 0 && (
          <MainImage
            width={width}
            rightGap={rightGap}
            profileSize={profileSize}
            textHeight={textHeight}
            pages={carousel.data}
            isLoading={!loaded ? !loaded : !pageLoad}
            openViewer={handleViewerOpen}
          />
        )}
        <Section type={MAIN} gender={'f'} data={Array.isArray(women.data) ? women.data : []} title="Women" />
        <Section type={MAIN} gender={'m'} data={Array.isArray(men.data) ? men.data : []} title="Men" />
        <div className="contact-section">
          <h2 className="title">Contact</h2>
          <Contact data={contact} />
        </div>

        <Footer data={contact} />
      </Layout>
    </>
  );
}

export default Home;
