import { combineReducers } from 'redux';
import common from './common';
import models from './models';
import men from './men';
import women from './women';
import carousel from './carousel';
import news from './news';

export default combineReducers({
  common,
  models,
  men,
  women,
  carousel,
  news,
});
