import React from 'react';
import { format, toDate } from 'date-fns';
import Image from './Image';
function Card({ img, name, model, date, onClick, onMouseOver, onMouseOut }) {
  const toConvertDate = toDate(new Date(date));
  const dateFormat = format(toConvertDate, 'dd MMMM yyyy');

  return (
    <div className="news-item">
      <div onClick={onClick} onMouseOver={onMouseOver} onMouseOut={onMouseOut}>
        <Image src={img} />
      </div>
      <h3 className="name">{name}</h3>
      <p className="model">{model}</p>
      <p className="date">{dateFormat}</p>
    </div>
  );
}

export default Card;
