import React, { useRef, useEffect } from 'react';
import { useSelector } from 'react-redux';
import env from 'env';
import { CURSOR_MOVE, CURSOR_DEFAULT } from 'type';
import { useActions } from 'lib/hooks';
import * as commonAction from 'store/modules/common';
import classnames from 'classnames';
import { withRouter } from 'react-router-dom';
import { MAIN, PAGE } from 'type';
import Context from './Context';
import InfoBox from './InfoBox';
import BackImage from 'components/Image/BackImage';
import { useWindowSize } from 'lib/hooks';
import { animated, useSpring, config } from 'react-spring';

function ProfileCard(props) {
  const context = props.data;
  const cardRef = useRef(null);
  const [{ offset }, setOffset] = useSpring(() => ({ offset: 0, config: config.slow }));
  const [windowWidth] = useWindowSize();
  const { scrollY } = useSelector(state => ({
    scrollY: state.common.scrollY,
  }));
  useEffect(() => {
    setOffset({ offset: scrollY });
  }, [scrollY, setOffset]);
  const { setCursorType } = useActions(commonAction);
  const handleClick = () => {
    props.history.push(`/models/${props.id}`);
  };
  const handleMouseOver = () => {
    setCursorType(CURSOR_MOVE);
  };
  const handleMouseOut = () => {
    setCursorType(CURSOR_DEFAULT);
  };
  const handleParallax = ({ element = null, offset = 0, render }) => {
    const windowHeight = window.innerHeight;
    if (element) {
      const offsetY = offset < 0 ? 0 : offset;
      const elemenTop = element.getBoundingClientRect().top + offsetY + 80;
      const elementPosition = elemenTop - windowHeight;
      const reset = windowHeight >= elemenTop;
      const isActive = offsetY >= elementPosition;
      const gap = Math.abs(offsetY - elementPosition);

      return render(gap, isActive, reset);
    }
  };
  const movePosition = o => {
    const target = cardRef.current;

    return handleParallax({
      element: target,
      offset: o,
      render: (value, isActive, reset) => {
        const distance = windowWidth > 768 ? 50 : 30;

        const itemTranslate = Math.min(0, value / 5 - distance);

        return `translate3d(0,${Math.abs(itemTranslate)}px,0)`;
      },
    });
  };
  const activeEle = () => {
    const target = cardRef.current;

    return handleParallax({
      element: target,
      offset: scrollY,
      render: (value, isActive, reset) => {
        if (reset) {
          return true;
        } else {
          return isActive ? true : false;
        }
      },
    });
  };

  return (
    <Context.Provider value={context}>
      <animated.div
        ref={cardRef}
        className={classnames(
          'profile-card',
          { active: activeEle() },
          { main: props.type === MAIN },
          { page: props.type === PAGE }
        )}
        style={{
          transform: offset.interpolate(movePosition),
        }}>
        <div onClick={handleClick} onMouseOver={handleMouseOver} onMouseOut={handleMouseOut}>
          <BackImage src={`${env.ENDPOINT_URL}/${props.data.img || ''}`} />
        </div>

        <InfoBox type={props.type} />
      </animated.div>
    </Context.Provider>
  );
}

export default withRouter(ProfileCard);
