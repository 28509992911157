import produce from 'immer';
import axios from 'axios';
const CAROUSEL_ITEMS_START = 'CAROUSEL_ITEMS_START';
const CAROUSEL_ITEMS_SUCCESS = 'CAROUSEL_ITEMS_SUCCESS';
const CAROUSEL_ITEMS_FAILURE = 'CAROUSEL_ITEMS_FAILURE';

export const setCarouselItems = () => dispatch => {
  dispatch({ type: CAROUSEL_ITEMS_START });

  return axios.get('/api/carousel').then(res => {
    return dispatch({ type: CAROUSEL_ITEMS_SUCCESS, payload: res.data });
  });
};

const initialState = {
  data: [],
  pending: false,
};

// reducer
export default function carousel(state = initialState, action) {
  switch (action.type) {
    case CAROUSEL_ITEMS_START: {
      const nextState = produce(state, draftState => {
        draftState.pending = true;
      });

      return nextState;
    }
    case CAROUSEL_ITEMS_SUCCESS: {
      const nextState = produce(state, draftState => {
        draftState.pending = false;
        draftState.data = action.payload;
      });

      return nextState;
    }
    case CAROUSEL_ITEMS_FAILURE: {
      const nextState = produce(state, draftState => {
        draftState.pending = false;
        draftState.data = action.payload;
      });

      return nextState;
    }

    default:
      return state;
  }
}
