import React, { useState, useEffect, useRef } from 'react';
import classnames from 'classnames';
function MainLoading({ load, onEnd }) {
  const [progress, setProgress] = useState(0);
  const loaderInterval = useRef(null);
  const loaderTimeout = useRef(null);
  const getRandomArbitrary = (min, max) => {
    return Math.random() * (max - min) + min;
  };
  useEffect(() => {
    loaderInterval.current = setInterval(() => {
      setProgress(progress + getRandomArbitrary(10, 20));
    }, 700);
    if (progress >= 70) {
      clearInterval(loaderInterval.current);
    }
    if (load === true) {
      setProgress(100);
      clearTimeout(loaderTimeout.current);
      loaderTimeout.current = setTimeout(() => {
        return onEnd(true);
      }, 1300);
    }

    return () => clearInterval(loaderInterval.current);
  }, [progress, load, onEnd]);

  return (
    <div className="main-loading-wrap">
      <div className="text-wrap">
        <div className={classnames('logo', { load: progress === 100 })}>
          <svg className="back" width="100" height="100" viewBox="0 0 100 100">
            <path
              fill="#e8e8e8"
              d="M23.7,52.8c13.2,0,23.8,10.6,23.8,23.6c0,13-10.7,23.6-23.8,23.6H0V52.8H23.7z M92.5,52.8v32.3h7.5V100H52.9
            V52.8H92.5z M65.8,0l10.6,9.8L87,0h13v47.2H52.9V0C52.9,0,65.8,0,65.8,0z M23.7,0c13.2,0,23.8,10.6,23.8,23.6S36.9,47.2,23.7,47.2H0
            V0H23.7"
            />
          </svg>
          <svg width="100" height="100" viewBox="0 0 100 100">
            <defs>
              <clipPath id="shape">
                <path
                  d="M23.7,52.8c13.2,0,23.8,10.6,23.8,23.6c0,13-10.7,23.6-23.8,23.6H0V52.8H23.7z M92.5,52.8v32.3h7.5V100H52.9
	V52.8H92.5z M65.8,0l10.6,9.8L87,0h13v47.2H52.9V0C52.9,0,65.8,0,65.8,0z M23.7,0c13.2,0,23.8,10.6,23.8,23.6S36.9,47.2,23.7,47.2H0
	V0H23.7"
                />
              </clipPath>
            </defs>
          </svg>
          <div className="progress" style={{ width: progress }} />
        </div>
        <div className={classnames('title', { load: progress === 100 })} />
      </div>
    </div>
  );
}

export default MainLoading;
