import React, { useContext } from 'react';
import env from 'env';
import Context from './Context';
import Badge from 'components/Status/Badge';
import { PAGE } from 'type';
function InfoBox({ type }) {
  const { name, height, bust, waist, hips, shoes, hair, eye, status, nationality } = useContext(Context);

  const renderContents = () => {
    if (type === PAGE) {
      return (
        <div className="contents">
          <div className="info">
            <span className="title">Height</span>
            <span className="value">{height}</span>
          </div>
          <div className="info">
            <span className="title">Size</span>
            <span className="value">
              {bust}/{waist}/{hips}
            </span>
          </div>
          <div className="info pc">
            <span className="title">Shoes</span>
            <span className="value">{shoes}</span>
          </div>
          <div className="info pc">
            <span className="title">Hair</span>
            <span className="value">{hair}</span>
          </div>
          <div className="info pc">
            <span className="title">Eyes</span>
            <span className="value">{eye}</span>
          </div>
        </div>
      );
    } else {
      return null;
    }
  };

  return (
    <div className="info-box">
      <div className="title">
        {nationality && (
          <div
            className="national"
            style={{ backgroundImage: `url(${env.NATIONAL_IMG(nationality.toLowerCase())})` }}
          />
        )}
        <h3>{name}</h3>
      </div>
      <Badge status={status} />

      {renderContents()}
    </div>
  );
}

export default InfoBox;
