import React, { useEffect, useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { useActions, useError } from 'lib/hooks';
import * as womenAction from 'store/modules/women';
import * as commonAction from 'store/modules/common';
import Model from 'views/Model';
import Layout from 'views/Layout';
import ErrorPage from 'components/Error/ErrorPage';
Women.propTypes = {
  location: PropTypes.any,
};
function Women({ location }) {
  const { setWomenItems } = useActions(womenAction);
  const { setPageLoaded } = useActions(commonAction);
  const { women } = useSelector(state => ({
    women: state.women,
  }));
  const [error, setError] = useError();
  const [loaded, setLoaded] = useState(false);
  const initLoad = useCallback(async () => {
    try {
      await setWomenItems();
    } catch (err) {
      setError(err);
    }
    setPageLoaded(true);
    setLoaded(true);
  }, [setWomenItems, setPageLoaded, setError]);
  useEffect(() => {
    initLoad();
  }, [initLoad]);

  return error ? (
    <ErrorPage type="500" />
  ) : (
    <Layout location={location.pathname}>
      {loaded && (
        <>
          {/* <Helmet title="Women" /> */}
          <Model title="Women" data={women.data} />
        </>
      )}
    </Layout>
  );
}

export default Women;
