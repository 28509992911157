import React, { useEffect, useRef } from 'react';
import { animated, useSpring, config } from 'react-spring';
import { useSelector } from 'react-redux';
import ProfileCard from 'components/ProfileCard/index';
import { useHistory } from 'react-router-dom';
const LIMIT_COUNT = 16;
interface SectionProps {
  data: any;
  type: string;
  title: string;
  gender: string;
}
function Section({ data, type, title, gender }: SectionProps) {
  const titleRef = useRef(null);
  const profileWrapRef = useRef(null);
  const [{ offset }, setOffset] = useSpring(() => ({ offset: 0, config: config.stiff }));
  const { scrollY } = useSelector((state: any) => ({
    scrollY: state.common.scrollY,
  }));
  useEffect(() => {
    setOffset({ offset: scrollY });
  }, [scrollY, setOffset]);
  const history = useHistory();
  const handleMoreLink = () => {
    history.push(`/${gender === 'f' ? 'women' : 'men'}`);
  };
  const handleParallax = ({
    element = null,
    offset = 0,
    render,
  }: {
    element: HTMLElement | null;
    offset: number;
    render: (gap: number, isActive: boolean, reset: boolean) => void;
  }) => {
    const windowHeight = window.innerHeight;
    if (element) {
      const elementPosition = element.offsetTop - windowHeight + 100;
      const gap = Math.abs(offset - elementPosition);
      const isActive = offset > elementPosition;

      const reset = windowHeight > element.offsetTop;

      return render(gap, isActive, reset);
    }
  };
  const titleOpacity = (o: any) => {
    const target = titleRef.current;

    return handleParallax({
      element: target,
      offset: o,
      render: (value, isActive, reset) => {
        if (reset) {
          return 1;
        } else {
          const opacity = 0 + value / 150;

          return isActive ? (opacity > 1 ? 1 : opacity) : 0;
        }
      },
    });
  };

  return (
    <>
      {data.length !== 0 && (
        <div className={`main profile-section ${title}`}>
          <animated.h2
            ref={titleRef}
            className="title"
            style={
              {
                opacity: offset.interpolate(titleOpacity),
              } as any
            }>
            {title}
          </animated.h2>
          <div className="profile-wrap" ref={profileWrapRef}>
            {data.map((item: any, i: number) => {
              return i < LIMIT_COUNT && <ProfileCard key={i} id={item.id} type={type} data={item} />;
            })}
          </div>
          {data.length > LIMIT_COUNT && (
            <div className="btn-more" onClick={handleMoreLink}>
              More
            </div>
          )}
        </div>
      )}
    </>
  );
}

export default Section;
