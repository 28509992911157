import React from 'react';
import { NavLink } from 'react-router-dom';
import classnames from 'classnames';
import Logo from './Logo';
function Nav({ active, move }) {
  const transitionDelay = delay => ({
    transitionDelay: `${active ? delay : 0}s`,
  });

  return (
    <div className={classnames('nav-items-wrap', { on: active })}>
      <h1 className="logo">
        <NavLink exact activeClassName="active" to="/">
          <Logo />
          <div className="text" />
        </NavLink>
        <div className="text" />
      </h1>
      <div className={classnames('nav-items', { on: active })}>
        <ul>
          <li>
            <NavLink activeClassName="active" to="/women" style={transitionDelay(0.35)}>
              Women
            </NavLink>
          </li>
          <li>
            <NavLink activeClassName="active" to="/men" style={transitionDelay(0.3)}>
              Men
            </NavLink>
          </li>
          <li>
            <NavLink activeClassName="active" to="/news" style={transitionDelay(0.4)}>
              News
            </NavLink>
          </li>
          <li>
            <NavLink activeClassName="active" to="/about" style={transitionDelay(0.45)}>
              About
            </NavLink>
          </li>
          <li>
            <NavLink activeClassName="active" to="/contact" style={transitionDelay(0.5)}>
              Contact
            </NavLink>
          </li>
        </ul>
      </div>
    </div>
  );
}

export default Nav;
