import React, { useEffect, useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { useActions, useError } from 'lib/hooks';
import * as menAction from 'store/modules/men';
import * as commonAction from 'store/modules/common';
import Model from 'views/Model';
import Layout from 'views/Layout';
import ErrorPage from 'components/Error/ErrorPage';
Men.propTypes = {
  location: PropTypes.any,
};
function Men({ location }) {
  const { setMenItems } = useActions(menAction);
  const { setPageLoaded } = useActions(commonAction);
  const { men } = useSelector(state => ({
    men: state.men,
  }));
  const [error, setError] = useError();
  const [loaded, setLoaded] = useState(false);
  const initLoad = useCallback(async () => {
    try {
      await setMenItems();
    } catch (err) {
      setError(err);
    }
    setLoaded(true);
    setPageLoaded(true);
  }, [setError, setMenItems, setPageLoaded]);
  useEffect(() => {
    initLoad();
  }, [initLoad]);

  return error ? (
    <ErrorPage type="500" />
  ) : (
    <Layout location={location.pathname}>
      {loaded && (
        <>
          {/* <Helmet title="Men" /> */}
          <Model title="Men" data={men.data} />
        </>
      )}
    </Layout>
  );
}

export default Men;
