import produce from 'immer';
import axios from 'axios';
const MODELS_ITEMS_START = 'MODELS_ITEMS_START';
const MODELS_ITEMS_SUCCESS = 'MODELS_ITEMS_SUCCESS';
const MODELS_ITEMS_FAILURE = 'MODELS_ITEMS_FAILURE';

const DETAIL_ITEM_START = 'DETAIL_ITEM_START';
const DETAIL_ITEM_SUCCESS = 'DETAIL_ITEM_SUCCESS';
const DETAIL_ITEM_FAILURE = 'DETAIL_ITEM_FAILURE';

export const setModelItems = () => dispatch => {
  dispatch({ type: MODELS_ITEMS_START });

  return axios.get('/api/models').then(res => {
    return dispatch({ type: MODELS_ITEMS_SUCCESS, payload: res.data });
  });
};
export const setDetailItem = id => dispatch => {
  dispatch({ type: DETAIL_ITEM_START });

  return axios.get(`/api/models/${id}`).then(res => {
    return dispatch({ type: DETAIL_ITEM_SUCCESS, payload: res.data });
  });
};

const initialState = {
  data: [],
  detailItem: {},
  pending: false,
};

// reducer
export default function models(state = initialState, action) {
  switch (action.type) {
    case MODELS_ITEMS_START: {
      const nextState = produce(state, draftState => {
        draftState.pending = true;
      });

      return nextState;
    }
    case MODELS_ITEMS_SUCCESS: {
      const nextState = produce(state, draftState => {
        draftState.pending = false;
        draftState.data = action.payload;
      });

      return nextState;
    }
    case MODELS_ITEMS_FAILURE: {
      const nextState = produce(state, draftState => {
        draftState.pending = false;
        draftState.data = action.payload;
      });

      return nextState;
    }
    case DETAIL_ITEM_SUCCESS: {
      const nextState = produce(state, draftState => {
        draftState.pending = false;
        draftState.detailItem = action.payload;
      });

      return nextState;
    }
    case DETAIL_ITEM_FAILURE: {
      const nextState = produce(state, draftState => {
        draftState.pending = false;
        draftState.detailItem = action.payload;
      });

      return nextState;
    }

    default:
      return state;
  }
}
