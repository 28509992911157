import React from 'react';

function Footer({ data }) {
  const { email, instagram, youtube } = data;

  return (
    <div className="footer-container">
      <div className="footer-left">
        <span className="logo" />
        <a href={`mailto:${email}`}>{email}</a>
      </div>
      <div className="footer-right">
        <a className="instagram" href={instagram}>
          <span className="hide">instagram</span>
        </a>
        <a className="youtube" href={youtube}>
          <span className="hide">youtube</span>
        </a>
        {/*<a className="facebook" href={facebook}>*/}
        {/*  <span className="hide">facebook</span>*/}
        {/*</a>*/}
        {/*<a className="twitter" href={twitter}>*/}
        {/*  <span className="hide">twitter</span>*/}
        {/*</a>*/}
      </div>
    </div>
  );
}

export default Footer;
