import React from 'react';
import { CSSTransition } from 'react-transition-group';
import * as commonAction from 'store/modules/common';
import { useSelector } from 'react-redux';
import { useActions } from 'lib/hooks';

import classnames from 'classnames';
function TransitionLayer({ location }) {
  const { pageLoad } = useSelector(state => ({
    pageLoad: state.common.pageLoad,
  }));
  const { setPageType } = useActions(commonAction);

  const handleEntering = () => {
    if (location.indexOf('models') > 0) {
      setPageType('detail');
    } else {
      setPageType('default');
    }
  };

  return (
    <CSSTransition in={pageLoad} timeout={1000} classNames="transition" onEntering={handleEntering}>
      <div className={classnames('transition-layer', { done: pageLoad })} />
    </CSSTransition>
  );
}

export default TransitionLayer;
