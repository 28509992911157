import React, { useState, useCallback } from 'react';
import { useSpring, animated } from 'react-spring';
import { isMobile } from 'react-device-detect';

function Hover3d({ children, className, width = 350, style, strength = 10 }) {
  const [clientWidth, setClientWidth] = useState(width);
  const calc = (x, y, e) => {
    const offsetX = e.currentTarget.getBoundingClientRect().left;
    const offsetY = e.currentTarget.getBoundingClientRect().top;
    const mouseX = (x - offsetX - clientWidth / 2) / clientWidth;
    const mouseY = (y - offsetY - clientWidth / 2) / clientWidth;

    return [-mouseY * strength, mouseX * strength, 1.05];
  };
  const trans = (x, y, s) => `perspective(${clientWidth * 2}px) rotateX(${x}deg) rotateY(${y}deg) scale(${s})`;
  const [card, setCard] = useSpring(() => ({ xys: [0, 0, 1], config: { tension: 350, friction: 40 } }));
  const handleEnter = useCallback(e => {
    setClientWidth(e.currentTarget.clientWidth);
  }, []);
  const handleMove = e => {
    const x = e.clientX;
    const y = e.clientY;
    setCard({ xys: calc(x, y, e) });
  };

  return isMobile ? (
    <div className={className} style={{ ...style }}>
      {children}
    </div>
  ) : (
    <animated.div
      className={className}
      onMouseEnter={handleEnter}
      onMouseMove={handleMove}
      onMouseLeave={() => setCard({ xys: [0, 0, 1] })}
      style={{ ...style, transform: card.xys.interpolate(trans) }}>
      {children}
    </animated.div>
  );
}

export default Hover3d;
